import { defineStore } from "pinia";
import { $axios } from "@bloglovin/vue-framework";

export const useTestStore = defineStore("test-store", {
    state: () => ({
        testData: [],
    }),
    getters: {
        getTestDataLength() {
            return this.testData.length;
        },
        getRandomTestDataItem() {
            return this.testData[Math.floor(Math.random() * this.getTestDataLength)];
        }
    },
    actions: {
        fetchTestData() {
            $axios.get("/test")
                .then(response => {
                    this.testData = response.data;
                });
        }
    }
});
