<template>
  <div class="text-blue">
    Hello world
  </div>
  <div>
    prop1 - {{ prop1 }}
  </div>
  <div>
    prop2 - {{ prop2 }}
  </div>
  <div>
    state1 - {{ state1 }}
  </div>
  <div>
    testData - {{ testData }}
  </div>
  <div>
    randomTestData - {{ getRandomTestDataItem }}
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useTestStore } from "@/stores/test-store";

export default {
  name: "Test",
  props: {
    prop1: {
      type: String,
      required: true,
    },
    prop2: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      state1: "state value",
    };
  },
  computed: {
    ...mapWritableState(useTestStore, ["testData"]),
    ...mapState(useTestStore, ["getRandomTestDataItem"]),
  },
  created() {
    this.fetchTestData();
  },
  methods: {
    ...mapActions(useTestStore, { fetchTestData: "fetchTestData" }),
  }
};
</script>

<style scoped>
.text-blue {
  color: steelblue;
}
</style>
