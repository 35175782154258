import { createPinia } from "pinia";
import { init, axiosInit } from "@bloglovin/vue-framework";

import views from "@/views";
import "@bloglovin/vue-component-library/style.css";

axiosInit(window.blclient.data.JWT, window.blclient.data.links.api);
let pinia = createPinia();

init(process.env.NODE_ENV, process.env.PROJECT_NAME, views, pinia);
